$c-primary: #0070f3;
$c-secondary: #757575;
$c-tertiary: #ffffff;
$c-success: #28a745;


//@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

/* Navbar styles */
.navbar-cert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 80px;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  .logo img{
    margin-left: 50px;
    width: 200px!important;
    height: 60px;
    
  }
  
  .nav-links {
    display: flex;
  }
  
  .nav-link {
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  
    &:hover {
      color: #000;
    }
  }
  .nav-item{
  margin-right: 50px;
  margin-top: 10px;
  right: 0;
  font-weight: 600;
  color: #000;
  }
  .nav-item a{
    text-decoration: none;
    font-size: 1.2rem;
    right: 0;
  font-weight: 600;
  color: #000;
  }
  
  .nav-item a:hover{
  color: #000;
  }
}



/* Footer styles */
.footerr {
  background-color: #e0e0e0;
  color: #333;
  text-align: center!important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.footerr-text {
  font-size: 14px;
  text-align: center!important;
}
.badge-containerr{
  margin-top: 30px!important;
  margin-bottom: 30px;
  width: 380px!important;
    height: 500px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    

    .badgeImgg{
      margin-top: 70px;
      width: 380px;
    height: 500px!important;
    }
}

.certificate-container {
  // display: flex;
  //   flex-direction: column;
    justify-content: center; /* Center vertically */
    align-items: center;
    width: 100%;
    height: 90vh; /* Use 90vh to ensure it occupies 90% of the viewport height */
    background-color: #f3f3f3;
  padding-bottom: 50px;
}



  .certificatedgbaa {
   // margin-top: -100px!important;
  // flex-direction: column;
 // align-items: center;
    height: 635px!important;
   display: flex;
   flex-direction: column;
    align-items: center;
    padding: 20px;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
   // border: 5px solid #013a63;
    //background-color: $c-tertiary!important;
    background-image: url('../../img/bottom.jpg');
    background-size: cover;
    //background-image: url('../../img/blue-abstract-gradient-wave-wallpaper.jpg');
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  }
  .bodboxcms {
    width: 740px;
    height: 550px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-image: url('../../img/cmsBlur.png');
    background-size: cover;
    background-repeat: no-repeat;
   // background: rgba(0, 0, 0, 0.1); /* Adjust transparency */
    // backdrop-filter: blur(15px); /* Adjust blur intensity */
    // -webkit-backdrop-filter: blur(15px); /* For Safari support */
    //border-radius: 10px; /* Optional: Add rounded corners */
    padding: 20px; /* Optional: Add padding */
    margin-top: 25px;
  }
  .backwatermark {
    background-image: url('../../img/middle.png');
    background-size: contain; /* Ensures the entire image is visible */
    background-repeat: no-repeat;
    background-position: center center; /* Centers the image both horizontally and vertically */
  }
 
  
  .favicocms {
    margin-left: 85%; /* Pushes the `.favico` div to the right */
  }
  
  .favicocms img {
    width: 100px !important;
    height: 50px!important;
    display: block; /* Ensures proper alignment */
  }
  

    .title11cms {
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #fff!important;
      margin-top: 25px;
      // font-family: 'Tangerine', cursive;
    }
    .title1cms{
      margin: 0;
      text-align: center;
      font-size: 0.9rem;
      color: #fff;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .gbaLogo{
      height: auto;
      width: 50%;
      margin: 0 auto!important;
      text-align: center;
    }
    // .signature{
    //   font-size: 1.1rem;
    //   font-weight: 600;
    // }
    .body {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title1{
        width: 85%;
      }

      .namecms {
        font-size: 25px;
        font-weight: bold;
       // font-family: 'Poiret One', cursive;
       //font-family: "Roboto Serif", serif;
        color: #fff!important;
        margin-bottom: 10px;
        //text-decoration: 2px underline;
      }
    }
    .qrcode-container{
      //padding: 15px;
    }
    .qrCodegba{
      height: 100px!important;
      width: 100px!important;
    }
  

  @media only screen and (max-width: 768px) {
    .badge-container{
      margin: 20px auto!important;
        width: 380px;
        height: 550px!important;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        
    
        .badgeImg{
          margin-top: 70px;
        }
      }
    .certificatedgbaa {
      margin: 20px auto!important;
      //width: max-content!important;
      display: flex;
      align-self: center;
      width: auto !important;
      height: auto!important;
      padding: 10px!important;


      .bodboxcms {
        width: 90%!important;
        height: auto !important;
        padding: 10px;
      }
    .header{
      display: block!important;
      align-items: center;
      margin-left: 30%;
    }
       .favicocms {
        
        margin-left: 32%!important;
      }
    
      .title11cms {
        font-size: 30px!important;
        margin-left: 0px!important;
      }
    
      .title1cms {
        font-size: 14px!important;
      }
     .body .title1{
        width: 130%!important;
      }
    
      .body .name {
        font-size: 28px!important;
      }
      .qrcode-container{
        padding: 5px!important;
      }
      .qrCodegba{
        height: 70px!important;
        width: 70px!important;
      }
      .icon-wrapper img{
        height: 50px;
        width: 50px;
        margin: 20px;
        padding: 10px;
        border-radius: 10px;
        }
        .icon-wrapper {
          display: flex;
          flex-direction:row;
          justify-content: center;
          align-items: center;
        }
    }
  
 
      
  }
  @media only screen and (max-width: 1100px) {
    .certificatee {
      width: auto !important;
      height: auto !important;
      padding: 10px!important;
    }
  
    .bodboxcms {
      width: 70%!important;
      height: auto !important;
      padding: 10px;
    }
  
    .header .favico img {
      width: 50px !important;
      height: 50px!important;
    }
  
    .header .title {
      font-size: 20px!important;
    }
  
    .title1 {
      font-size: 14px!important;
    }
  
    .body .name {
      font-size: 28px!important;
    }
    .qrcode-container{
      padding: 5px!important;
    }
    .qrCode{
      height: 30px!important;
      width: 30px!important;
    }
    .icon-wrapper img{
      height: 50px;
      width: 50px;
      margin: 20px;
      padding: 10px;
      border-radius: 10px;
      }
      .icon-wrapper {
        display: flex;
        flex-direction:row;
        justify-content: center;
        align-items: center;
      }
      
  }

  

  .infosecgba{
    margin: 50px 125px;
  }
.leftsec{
  padding-right: 50px;

}
.coursename{
  font-weight: 600;
  font-size: 2rem;
  color: #013a63;
  text-align: left;
  padding-left: 25px!important;
  padding-top: 10px;
}
.check img{
  height: 60px;
  width: 60px;
}
.stuname{
  margin-top: 50px;
  padding-left: 0px!important;
  font-size: 1.7rem;
  text-align: left;
}
.stuID{
  padding-left: 0px!important;
  text-align: left;
  font-size: 1.3rem;
}
.description{
  text-align: left;
  color: #757575;
  padding-top: 20px;
  font-size: 1.1rem;
  padding-bottom: 20px;
}
.studNameDiv{
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  text-align: left;
  font-weight: 600;
}
.issuerNamepopcms{
  display: flex;
  align-items: center;
  //padding-left: 0px!important;
  font-size: 1.5rem;
  text-align: left;
  margin-left: 20px;
  color: #7f7f7f;
  font-weight: 600;
  .issuerfontIcon1{
    color: #b6b6b6;
  }
  .issuerfontIconcms{
    color: #b6b6b6;
  }
}
.issuerName{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 0px!important;
  font-size: 1.7rem;
  text-align: left;

  .issuerfontIcon1{
    color: #013a63;
  }
  .issuerfontIcon{
    color: #b6b6b6;
  }
  .gbaLogo1{
    height: auto;
    width: 30%!important;
  }
}
.viewCreds{
  color: #2d9cdb!important;
  font-weight: 600!important;
  margin-left: 60px;
}
.issueon, .expiry{
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  margin: 20px 0;
}

.footer2tcms {
 

  .sharecontainer{
    display: flex;
    justify-content: end;
  }

  .yellowBadget img{
    margin-top: 10px;
    height: 70px;
    width: 70px;
  }
  .iconns-div{
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    @media only screen  and (max-width: 790px){
      margin-left: 20px;
     }
  }
  .iconns-divcms{
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px!important;
    margin-left: auto;
    margin-right: auto;
    @media only screen  and (max-width: 790px){
      margin-left: 20px;
     }
  }
  .iconns {
    
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 20px!important; /* Adjust font size as needed */
    color: #467fbd;
    margin-right: 16px;
    padding: 18px;
    
    background: #fff;
    border-radius: 5px;
  }
  .iconns1 {
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 30px; /* Adjust font size as needed */
    color: #467fbd;
    margin-right: 8px;
    
  }
  .iconns2{
    height: 20px;
    width: 20px;
    
  }
  .iconns3 {
    
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 20px!important; /* Adjust font size as needed */
    color: #467fbd;
    margin-right: 16px;
    padding: 13px 18px 13px 18px;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 5px;
  }

  // .verify-btn {
  //   margin-top: 20px;
  //   color: #2d9cdb;
  //   background-color: #fff;
  //   border: none;
  //   border-radius: 10px;
  //   cursor: pointer;

   
  // }
}
.footer22 {
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

.verify{
font-size: 1.5rem;
font-weight: 600;
color: #333!important;
text-align: left;
padding: 10px 0 20px 0;

}
.verify1{
display: flex;
align-items: center;
margin-bottom: 10px;
font-size: 1rem;
font-weight: 600;
color: #757575;
text-align: left;
}
.fontIcon22{
  color: #3498db;
  margin-right: 30px!important;
  padding: 0!important;
  margin-left: 30px;
}
}

.footer2tcms{
  margin: 0 0 20px 0;
  padding: 30px;
  width: 100%;
  height: max-content;
  justify-content: center;
  align-items: center;
 // border-radius: 10px;
 color: #fff;
 //background-color: #2d9cdb;
 background: linear-gradient(90deg, #ffffff 0%, #afd7da 100%);
 // border: 1px solid #c3c3c3;
  //box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

.verify{
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  text-align: left;
  padding: 10px 0 20px 0;
  
}
.verify1{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 600;
  color: #757575;
  text-align: left;

  span{
    color: #000;
    margin-left: 7px;
  }
  .fontIcon21{
    color: #2d9cdb;
    margin-right: 10px!important;
    padding: 0!important;
  }
  .fontIcon22{
    color: #ffb700;
    margin-right: 10px!important;
    padding: 0!important;
  }
}
    .verify-btn {
      display: flex;
  align-items: center;
  justify-content: center;
      margin-top: 10px;
      width: 100%;
      height: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 700;
      color: #2d9cdb;
      background-color: #fff;
      border: 0.5px solid #c3c3c3;
      border-radius: 10px;
      cursor: pointer;

     
      // &:hover {
      //   background-color: darken(#fff, 10%);
      // }
    }
    .issuerfontIcon{
      color: #2d9cdb;
      background: none;
      margin-left: 0px;
    }

    .logowhite{
      height: 60px;
      width: 140px;
      margin: 30px auto 90px auto!important;
    }
    .iconns-divgba{
     margin-left: 35px;
     @media only screen  and (max-width: 1200px){
      margin-left: 10px;
     }
    }
  }
  

  .pdfbtn{
    display: flex;
  align-items: center;
  justify-content: center;
    margin-top: 40px;
    margin-left: 10px!important;
    margin-bottom: 40px;
      height: 50px;
      padding: 10px 10px;
      font-size: 16px;
      font-weight: 500!important;
      color: #000;
      background-color: #fff;
      border-radius: 7px;
      border: 1px solid #e7e7e7!important;
      transition: 0.3s;
      cursor: pointer;
      justify-content: center;

      .fontIcon{
        background: transparent;
        padding: 0; /* Adjust padding as needed */
        font-size: 16px; /* Adjust font size as needed */
        color: #6c6c6c;
        margin-right: 8px;
      }
      .fontIcon1{
        background: transparent;
        padding: 0; /* Adjust padding as needed */
        font-size: 18px; /* Adjust font size as needed */
        color: #6c6c6c;
        margin-left: 8px;
      }
  }
  .pdfbtn:hover{
    background-color: #e7e7e7;
  }

  .verify-btnn{
    margin-top: 40px;
      width: 50%;
      height: 50px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 400;
      color: $c-tertiary;
      background-color: #2d9cdb;
      border: none;
      border-radius: 10px;
      cursor: pointer;
  }

  .skillsdiv p{
color: #000;
font-size: 1.2rem;
margin: 10px 0;
font-weight: 600;
  }
  .allSkills{
    margin: 10px;
    color: #000;
    background-color: #e7e7e7;
    padding: 10px;
    border-radius: 5px ;
  }

.certificate-swal{
  width: 100%!important;
  text-align: left!important;
}

  .popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;

    .spinner {
      margin-left: 70px;
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      animation: spin 2s linear infinite;
    }
    
    .spinner-text {
      margin-top: 10px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
    
    .spinner1 {
      border: 5px solid #f3f3f3;
      border-top: 5px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 2s linear infinite;
    }
    @keyframes spin {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }

  .success {
    color: #3498db;
    font-size: 24px;
  }
    .popup {
      width: 600px!important;
      background-color: $c-tertiary;
      border-radius: 20px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      padding: 20px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .popup-title {
          font-size: 1.8rem;
          font-weight: bold;
          color: #013a63;
          text-align: center;
          margin-top: 20px;
          margin-left: 30px;
        }

        .popup-close .fontIcon21cms{
          position: relative;
          top: 0px;
          right: 0px;
          font-size: 25px!important;
          color: #7f7f7f;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none;
        }
        
        .popup-body {
          padding: 20px;
        }

        
        
        // .verification-item {
        //   display: flex;
        //   justify-content: space-between;
        //   align-items: center;
        //   margin-bottom: 10px;
        // }
        
        // .verification-label {
        //   font-weight: bold;
        //   font-size: 1.2rem;
        //   margin-right: 10px;
        //   color: #3498db!important;
        // }
        
        // .verification-result {
        //   display: flex;
        //   align-items: center;
        //   color: #fff;
        //   background-color: #5ebd73;
        //   padding: 5px 10px;
        //   border-radius: 5px;
        // }
        
        // .success {
        //   background-color: #5ebd73;
        // }
        
        .popup-footer {
          padding: 20px;
          background-color: #fff;
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid #ddd;
        }
        
        .share-btn {
          background-color: #2d9cdb;
          color: #fff;
          font-weight: bold;
          border-radius: 5px;
          padding: 10px 20px;
          border: none;
          outline: none;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
        }
        
        .share-btn:hover {
          transform: translateY(-2px);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
        
        .share-btn:active {
          transform: translateY(0);
          box-shadow: none;
        }
      }
    }
  }
  .verification-itemm {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    margin: 0 40px;
  }

  .verification-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  .verification-item .verification-label {
    font-weight: bold;
    margin-right: 10px;
    margin-left: 0px;
  }
  .verification-item .verification-result {
    display: flex;
    align-items: center;
  }
  .verification-result1cms{
    margin-left: 90px;
  }
  .verification-item .spinner1,
  .verification-item .success {
    margin: 0 30px;
    font-size: 20px;
    width: 30px;
      height: 30px;
  }
  .verified{
    font-weight: bold;
    font-size: 1.4rem;
    margin-left: 25px;
  }
  .verified1{
    margin-left: 30px;
  }
  .success1 img{
    height: 45px;
    width: 45px;
    margin-left: 20px;
  }
  .issuedOncms .verification-label{
    font-weight: bold;
    margin-right: 10px;
    margin-left: 0px;
  }

  /* Responsive Styles */
@media only screen and (max-width: 1200px) {
  .navbar-cert {
    padding: 0 10px;
  }
  .logo img {
    margin-left: 10px;
    width: 120px !important;
    height: 40px;
  }
  .nav-links {
    display: none;
  }
  .nav-item {
    margin-right: 10px;
    margin-top: 5px;
  }
  .nav-item a {
    font-size: 1rem;
  }
  // .badge-container,
  // .certificate-container {
  //   width: 100%;
  //   height: auto !important;
  //   padding: 10px;
  // }
  .footer11 {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  .popup {
    width: 90%;
    max-width: 450px;
  }
  .popup-title {
    font-size: 1.6rem;
  }
  .popup-body,
  .popup-footer {
    padding: 10px;
  }
  // .pdfbtn {
  //   width: 20%;
  // }
  .rightsec,
  .leftsec {
    padding: 20px;
  }
  .iconns {
    
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 13px!important; /* Adjust font size as needed */
    color: #013a63;
   // margin-right: 0px;
    padding: 8px!important;
  }
  .iconns2{
    height: 18px!important;
    width: 18px!important;
    
  }
  .iconns3 {
    
    background: transparent;
    padding: 0; /* Adjust padding as needed */
    font-size: 13px!important; /* Adjust font size as needed */
    color: #467fbd;
    margin-right: 16px;
    padding: 3px 8px 3px 8px!important;
    margin-bottom: 4px;
    background: #fff;
    border-radius: 5px;
  }
  .shareIcon{
    cursor: pointer!important;
    margin-top: 5px;
  }
  .rightsec {
    text-align: center;
  }
  .infosecgba {
    margin: 0;
  }
  .coursename {
    padding-left: 15px !important;
  }
  .description {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .viewCreds {
    margin-left: 10px;
  }
}



/* Additional Responsive Styles for Even Smaller Devices */
@media only screen and (max-width: 480px) {
  .certificatee {
    width: 90%;
    height: auto !important;
    padding: 5px;
  }

  .bodboxcms {
    width: 90%;
    height: auto !important;
    padding: 5px;
  }

  .header .favico img {
    width: 40px !important;
    height: 40px;
  }

  .header .title {
    font-size: 24px;
  }

  .title1 {
    font-size: 16px;
  }

  .body .name {
    font-size: 20px;
  }
}

/* Additional Responsive Styles for Smaller Devices */
@media only screen and (max-width: 1200px) {
  .navbar-cert {
    height: 50px;
    padding: 0 5px;

    .logo img {
      margin: 10px 5px 5px 5px!important;
      width: 100px !important;
      height: 30px;
    }
  }

  .nav-links {
    display: none;
  }
  .nav-item {
    margin-right: 5px!important;
    margin-top: 5px!important;
  }
  .nav-item a {
    font-size: 0.85rem!important;
  }
  .badge-container,
  .certificate-container {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    padding: 5px;
  }
  // .footer11 {
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  // }
  .popup {
    width: 90%;
    max-width: 400px;
    height: 70vh!important;
    scroll-behavior: auto;
  }
  .popup-title {
    font-size: 1.2rem;
  }
  .popup-body,
  .popup-footer {
    padding: 5px;
  }
  // .pdfbtn {
  //   width: 100%;
  // }
  .rightsec,
  .leftsec {
    //margin: 30px!important;
  }
  .rightsec {
    text-align: center;
  }
  .infosecgba {
    margin: 0;
  }
  .coursename {
    padding-left: 25px !important;
    font-size: 1.5rem!important;
  }
  .check img{
    height: 50px!important;
    width: 50px!important;
  }
  .description {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .viewCreds {
    margin-left: 5px;
  }
  .icon-wrapper img {
    height: 100px;
    width: 100px;
    margin: 10px;
    padding: 10px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .pdfbtn {
    margin-top: 20px;
  }
  .leftsec {
    padding-right: 10px;
  }
  .rightsec {
    padding-left: 10px;
  }
  // .footer2 {
  //   margin: 20px 0 !important;
  // }
  .verify {
    font-size: 1rem;
  }
  .verify1 {
    font-size: 0.8rem;
  }
  .verify-btnn {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .yellowBadge img{
    height: 80px!important;
    width: 50px!important;
  }
}

@media only screen and (max-width: 1000px) {
  .navbar-cert {
    height: 60px;
    padding: 0 5px;
  }
  .logo img {
    margin-left: 5px;
    width: 100px !important;
    height: 30px;
  }
  .nav-links {
    display: none;
  }
  .nav-item {
    margin-right: 5px;
    margin-top: 5px;
  }
  .nav-item a {
    font-size: 1rem;
  }
  .badge-container,
  .certificate-container {
    width: 100%;
    max-width: 100%;
    height: auto !important;
    padding: 5px;
  }
  // .footer11 {
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  // }
  .popup {
    width: 90%;
    max-width: 400px;
  }
  .popup-title {
    font-size: 1.2rem;
  }
  .popup-body,
  .popup-footer {
    padding: 5px;
  }
  // .pdfbtn {
  //   width: 100%;
  // }
  .rightsec,
  .leftsec {
    margin: 30px!important;
  }
  .rightsec {
    text-align: center;
  }
  .infosecgba {
    margin: 0;
  }
  .coursename {
    padding-left: 25px !important;
    font-size: 1.5rem!important;
  }
  .check img{
    height: 50px!important;
    width: 50px!important;
  }
  .description {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .viewCreds {
    margin-left: 5px;
  }
  .icon-wrapper img {
    height: 100px;
    width: 100px;
    margin: 10px;
    padding: 10px;
  }
  .icon-wrapper {
    margin-right: 10px;
  }
  .pdfbtn {
    margin-top: 20px;
  }
  .leftsec {
    padding-right: 10px;
  }
  .rightsec {
    padding-left: 10px;
  }
  // .footer2 {
  //   margin: 20px 0 !important;
  // }
  .verify {
    font-size: 1rem;
  }
  .verify1 {
    font-size: 0.8rem;
  }
  .verify-btnn {
    margin-top: 20px;
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .yellowBadge img{
    height: 80px!important;
    width: 50px!important;
  }
}


.swal-custom-close-button {
  // Add custom styles for the close button if needed
  font-family: 'Lato', serif!important; /* Replace with your desired font family */
  font-size: 16px; 
  color: #000;
  background-color: transparent;
  border: 0px solid #fff;
  font-size: 1.7rem;
}
.swal-custom-close-button:hover{
  //font-size: 20px;
color: #000
}

/* Custom styles for SweetAlert2 popup */
.swal-custom-popup {
  font-family: 'Lato', serif!important; /* Replace with your desired font family */
  font-size: 16px; /* Adjust the font size as needed */
  //color: #333; /* Adjust the text color as needed */
}

.swal-custom-title{
  font-family: 'Lato', serif!important;
  color: #000;
}

// @media (max-width: 768px) {
//   .hide-on-mobile {
//     display: none !important;
//   }
// }