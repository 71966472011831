.containerev {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: #f0f2f5;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
    height: 85vh;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
    margin-right: 20px;
}

.right-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 60%;
    margin-left: 20px;
    overflow: hidden;
    height: 100%;
}

.input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
    margin-top: 10px;
}

.input-container input {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
}

.input-label {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 14px;
    color: #2a7ec9;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: -15px;
    margin-bottom: 10px;
}

.text-danger {
    color: red;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
    display: block;
}

.disabled-btn {
    background-color: #ccc !important;
    cursor: not-allowed !important;
}


.input-container button {
    background-color: #2ea3f2;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.input-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}


.response-container {
    background: #fff;
    color: #212529;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    height: calc(100% - 40px);
    overflow-y: auto;
}

pre {
    text-align: left;
    margin: 0;
}

.separator {
    width: 100%;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 20px 0;
}

.linee {
    height: 100%;
    width: 1.2px;
    background-color: #d4d4d4;
}

.instext p{
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 16px!important;
}
.left-pane h3, .right-pane h3{
    margin-bottom: 10px!important;
    margin-top: 10px;
}


.detail-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 0;
    border-bottom: 1px solid #dce7f3;
}

.label {
    flex: 1;
    font-weight: bold;
    color: #2ea3f2; /* light blue theme color */
    text-align: left;
    padding-right: 10px;
    white-space: nowrap;
}

.label::after {
    content: ":";
    margin-left: 4px;
}

.value {
    flex: 2;
    color: #333;
    text-align: right;
    word-break: break-word;
}

.value ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
    text-align: left;
}


.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.profile-header .name {
    font-size: 24px;
    font-weight: bold;
    color: #2ea3f2; /* Theme blue */
}

.profile-header img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    border: 2px solid #cde4f5;
}


.permission-section {
    margin-top: 30px;
    background-color: #f6fbff;
    border: 1px solid #d1eaff;
    padding: 15px 20px;
    border-radius: 8px;
}

.permission-label {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px;
}

#allowed{
    color: green
}

#restricted{
    color: red
}

.permission-list {
    margin: 0;
    padding-left: 5px;
    color: #444;
}

.permission-list li {
    margin-bottom: 15px;

}

.bullet-icon {
    font-size: 12px;
    color: #2ea3f2;
    margin-right: 15px;
    vertical-align: middle;
    background: none;
    padding: 0;
}
